export function setupClaimCode() {
  const toggleButton = document.getElementById("toggleClaimCode");
  const claimCodeBox = document.getElementById("claimCodeBox");

  if (toggleButton && claimCodeBox) {
    toggleButton.addEventListener("click", function (event) {
      event.stopPropagation();
      claimCodeBox.classList.toggle("hidden");
    });

    document.addEventListener("click", function (event) {
      if (event && event.target) {  // Add this check
        if (
          !claimCodeBox.contains(event.target) &&
          event.target !== toggleButton
        ) {
          claimCodeBox.classList.add("hidden");
        }
      }
    });
  }
}
